// Core Styles
#html {
	position: relative;
}

.modernizr-no-js .lazy
{
	display: none;
}

#body {
	position: relative;
	min-height: 100vh;

	.alert {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		right: 0.5rem;
	}

	.modal {
		.far {
			opacity: 1;
		}

		.far.btn-close {
			background: none;
		}

		.row {
			margin-bottom: 1rem;
		}

		.row:last-child {
			margin-bottom: 0px;
		}
	}

	#easycontact-modal {
		#privacy-statement {
			text-align: justify;
		}
	}

	#header {
		margin-bottom: 1.75rem;

		> .container {
			> .row {
				> .col-12 {
					text-align: right;
				}
			}
		}

		#social {
			padding: 0.5rem 0px;
			text-align: left;

			a, span {
				display: inline-block;
				padding: 0.5rem;
				color: inherit;
				font-size: 1.5rem;
				line-height: 1.5rem;
				cursor: pointer;
			}

			> :first-child {
				padding-left: 0px;
			}

			> :last-child {
				padding-right: 0px;
			}
		}

		#locales {
			display: inline-block;
			padding: 0.5rem 0px;
			text-align: right;

			a {
				display: inline-block;
				padding: 0.5rem;
				font-family: $headings-font-family;
				font-size: 1rem;
			}

			a.active {
				text-decoration: underline;
			}

			a:first-child {
				padding-left: 0px;
			}
		}

		.footer-copyright {
			display: inline-block;
			padding: 0.5rem;
			padding-right: 0px;
			font-family: $headings-font-family;
			font-size: 1rem;
		}

		#navigation {
			position: relative;

			#navigation-toggle {
				padding: 0.5rem 1rem;
				padding-right: 0px;
				color: inherit;
				font-size: 1.8rem;
				line-height: 1.5;
				font-family: $headings-font-family;
				border: none;

				&:focus {
					box-shadow: none;
				}

				.navbar-toggle-expand,
				.navbar-toggle-collapse {
					font-size: 1.5rem;
				}

				.navbar-toggle-expand {
					display: none;
				}

				.navbar-toggle-collapse {
					display: inline-block;
					font-weight: normal;
				}
			}

			#navigation-toggle.collapsed {
				.navbar-toggle-expand {
					display: inline-block;
				}

				.navbar-toggle-collapse {
					display: none;
				}
			}

			#navigation-items {
				flex-direction: row;

				#navigation-logo {
					display: none;
					width: 64px;
					height: 64px;

					picture {
						display: block;
						background: $secondary;
						border-radius: 50%;
						box-shadow: 0px 0px 3px 2px $secondary;

						img {
							width: 100%;
						}
					}
				}

				.nav-item {
					.nav-link {
						padding: 0.5rem 1rem;
						font-size: 1.8rem;
						font-family: $headings-font-family;
						text-decoration: none;

						.nav-link-icon {
							display: none;
						}
					}

					.nav-link:hover {
						text-decoration: underline;
					}
				}

				.nav-item:first-child {
					.nav-link {
						padding-left: 0px;
					}
				}

				.nav-item:last-child {
					.nav-link {
						padding-right: 0px;
					}
				}
			}
		}
	}

	#content {
		h1 {
			font-size: 3rem;
			margin-bottom: 1rem;
		}

		h2 {
			font-size: 2.8rem;
			margin-bottom: 1rem;
		}

		#antigone {
			.banner {
				position: relative;
				height: 35vw;
				min-height: 512px;
				max-height: 592px;
				margin-bottom: 5rem;

				.banner-guide {
					position: relative;
					height: 0px;

					.banner-text {
						position: absolute;
						display: flex;
						top: 0px;
						left: -2rem;
						z-index: 2;
						padding: 2rem;
						padding-top: 3rem;
						align-items: center;
						flex-direction: column;
						justify-content: center;
						color: $background-secondary-color;
						text-align: center;
						background-color: $secondary;
						box-shadow: 0px 1px 3px 0px $primary-grey;

						h1 {
							margin-bottom: 0px;
							font-weight: normal;
						}

						picture {
							display: block;
							margin-bottom: 1rem;
							overflow: hidden;

							img {
								max-width: 192px;
							}
						}
					}
				}

				.banner-image {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					overflow: hidden;

					img {
						position: absolute;
						bottom: 0px;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: 50% 100%;
					}
				}
			}

			#about {
				margin-bottom: 5rem;
				align-items: center;

				picture {
					display: block;
					margin-left: 5rem;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				> .row {
					> .col-lg-6 {
						align-self: center;
					}
				}
			}
		}

		#products {
			margin-bottom: 5rem;
			padding: 2rem 0px;
			color: $primary;
			background-color: $secondary;

			> .container {
				align-items: center;

				picture {
					display: block;
					margin-left: 5rem;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				> .row {
					> .col-lg-6 {
						align-self: center;
					}
				}
			}
		}

		#contact {
			margin-bottom: 5rem;

			#contact-form {
				.row {
					margin-bottom: 1rem;
				}

				.row:nth-last-child(2) {
					margin-bottom: 0.5rem;
				}

				.row:last-child {
					margin-bottom: 0px;
				}

				#privacy-statement {
					text-align: justify;
				}
			}
		}

		#gallery {
			.gallery {
				display: flex;
				flex-wrap: wrap;

				.gallery-cell {
					position: relative;
					flex: 0 0 auto;
					width: 25%;
					max-width: 100%;
					overflow: hidden;

					picture {
						display: block;
						width: 100%;
						height: 20vw;
						max-height: 400px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.gallery-fullscreen-button {
						position: absolute;
						top: 0px;
						left: 0px;
						z-index: 1;
						display: flex;
						width: 100%;
						height: 100%;
						align-items: flex-start;
						justify-content: flex-end;
						color: $primary;
						font-size: 1rem;
						opacity: 0.7;
					}

					.gallery-fullscreen-button:before {
						display: none;
						padding: 10px;
					}
				}

				.gallery-cell:hover {
					cursor: pointer;
				}
			}

			> .row {
				picture {
					display: block;
					width: 100%;
					height: 400px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				> .col-3 {
					position: relative;
					overflow: hidden;

					.gallery-image-overlay {
						position: absolute;
						top: 0px;
						left: 0px;
						z-index: 1;
						width: 0px;
						height: 100%;
						background-color: rgba($secondary, 0.7);
						transition: width 0.5s ease;
					}
				}

				> .col-3:hover {
					.gallery-image-overlay {
						width: 100%;
					}
				}
			}
		}

		#googlemaps {
			width: 100%;
			height: 400px;
			border: 0;
		}
	}
}

@include media-breakpoint-down(xl) {
#body {
	#content {
		h2, .h2 {
			font-size: 2.6rem;
		}

		#antigone {
			.banner {
				margin-bottom: 3rem;
			}

			#about {
				margin-bottom: 3rem;
			}
		}

		#products {
			margin-bottom: 3rem;
		}

		#contact {
			margin-bottom: 3rem;
		}

		#gallery {
			.gallery {
				.gallery-cell {
					width: 33.3333333333%;
					width: calc(100% / 3);

					picture {
						height: 28vw;
					}
				}
			}
		}
	}
}
}

@include media-breakpoint-up(lg) {
#body {
	#content {
		#gallery {
			.gallery {
				.gallery-cell {
					.gallery-fullscreen-button {
						width: 0px;
						align-items: center;
						justify-content: center;
						font-size: 1.2rem;
						background-color: rgba($secondary, 0.7);
						transition: width 0.5s ease;
						opacity: 1;
					}

					.gallery-fullscreen-button:before {
						display: none;
						padding: 20px 18px 20px 20px;
						width: 62px;
						height: 62px;
						line-height: 20px;
						text-align: center;
						border: 1px solid $primary;
						border-radius: 50%;
					}
				}

				.gallery-cell:hover {
					.gallery-fullscreen-button {
						width: 100%;
					}

					.gallery-fullscreen-button:before {
						display: inline-block;
					}
				}
			}
		}
	}
}
}

@include media-breakpoint-down(lg) {
#body {
	#header {
		margin-bottom: 1rem;

		#navigation {
			justify-content: flex-end;

			#navigation-toggle {
				font-size: 1.6rem;

				.navbar-toggle-expand,
				.navbar-toggle-collapse {
					font-size: 1.4rem;
				}
			}
		}
	}

	#header.expand {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		z-index: 3;
		background: $primary;

		#navigation {
			box-shadow: 1px -2px 2px -2px rgba($secondary, 0.3);

			#navigation-items {
				flex-direction: column;
				margin-top: 0.5rem;
				padding-top: 3rem;
				background: $primary;
				box-shadow: 1px -2px 2px -2px rgba($secondary, 0.3);

				.nav-item {
					.nav-link {
						padding-left: 0px;
						padding-right: 0px;
					}
				}

				#navigation-logo {
					display: block;
					margin-top: 3rem;
					text-align: center;
				}
			}
		}
	}

	#content {
		#antigone {
			.banner {
				height: 448px;
				min-height: initial;
				max-height: initial;
			}

			#about {
				picture {
					margin-top: 1rem;
					margin-left: 0px;
				}
			}
		}

		#products {
			> .container {
				picture {
					margin-top: 1rem;
					margin-left: 0px;
				}
			}
		}

		#contact {
			#contact-form {
				.row {
					.col-lg-6:first-child {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}
}

@include media-breakpoint-down(md) {
#body {
	#header {
		#navigation {
			#navigation-toggle {
				font-size: 1.4rem;

				.navbar-toggle-expand,
				.navbar-toggle-collapse {
					font-size: 1.2rem;
				}
			}
		}
	}

	#content {
		h2, .h2 {
			font-size: 2.4rem;
		}

		#gallery {
			.gallery {
				.gallery-cell {
					width: 50%;

					picture {
						height: 45vw;
					}
				}
			}
		}
	}
}
}

@include media-breakpoint-down(sm) {
#body {
	#header {
		.container {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	#content {
		h1 {
			font-size: 3.6rem;
		}

		.container {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		#antigone {
			.banner {
				height: 384px;

				.banner-guide {
					.banner-text {
						left: 0px;
						right: 0px;
						height: 384px;
						background-color: transparent;
						text-shadow: 0 0 20px $secondary;

						picture {
							background: $secondary;
							border-radius: 50%;
							box-shadow: 0px 0px 3px 2px $secondary;
						}
					}
				}
			}
		}
	}
}
}

@media (max-width: 512px) {
#body {
	#content {
		#gallery {
			.gallery {
				.gallery-cell {
					width: 100%;

					picture {
						height: 95vw;
					}
				}
			}
		}
	}
}
}

@media (max-width: 404px) {
.g-recaptcha {
	> div {
		width: auto !important;
		height: 58px !important;

		> div {
			transform: scale(0.62, 0.72);
			transform-origin: 0 0;
		}
	}
}
}
